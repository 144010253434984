
.shippingPrice ul {
    padding-left: 0;
    margin-left: 1.75em;
    list-style-type:disc;
    margin-bottom:0px;
}
.shippingPrice ul li span.free {
    font-weight:600;
    color: #28a745
}
.shippingPrice {
    margin-top:5px;
    padding:10px;
    border: 0px;
    border-bottom: 1px solid rgba(0,0,0,0.075);
    box-shadow: 0 0 10px 0px rgba(0,0,0,.1);
}
.shippingPrice .country {
    margin-top:10px;
}
.shippingPrice .country:first-child {
    margin-top:0px;
}
.shippingPrice .country > span {
    display:block;
}
.shippingPrice .location {
    margin-left: 1.75em;
    margin-bottom: 5px;
}
.shippingPrice .location:last-child {
    margin-bottom:0px;
}



.shippingPriceCollapse > a i:last-child {
    margin-left:5px;
    transition: 0.3s ease all;
}
.shippingPriceCollapse > a.opened i:last-child {
    transform: rotate(180deg);
}



.sticky-cart {
    position:-webkit-sticky;
    position:sticky;
    bottom:0px;
    border-top: 2px solid var(--secondary-color);
    background-color: var(--content-background-color);
    z-index: 50;
}
.sticky-cart.opened .summary {
    padding-bottom: 10px;
}
.sticky-cart .summary .container-md {
    display:flex;
    flex-wrap: wrap;
}
.sticky-cart .summary .title, .sticky-cart .summary .total {
    text-transform: uppercase;
    line-height: 34px;
    font-weight: 600;
}
.sticky-cart .summary .title a.details i:first-child {
    margin-right:10px;
}
.sticky-cart .summary .title a.details i:last-child {
    margin-left:10px;
    transition: 0.3s ease all;
}
.sticky-cart.opened .summary .title a.details i:last-child {
    transform: rotate(180deg);
}
.sticky-cart .summary .total {
    flex-grow:1;
    text-align: center;
}
@media (max-width: 575.98px) {
.sticky-cart .summary {
        padding-bottom: 10px;
}
.sticky-cart .summary .title {
        flex-grow: 1;
}
.sticky-cart .summary .total {
        flex-grow: 0;
}
.sticky-cart .summary .button {
        width:100%;
}
}
.sticky-cart .details .empty {
    padding:10px;
    text-align: center;
}
.sticky-cart .details .table th {
    border-top:0px;
}



.shop-information h2 {
  font-size: 20px;
  margin-bottom: 10px;
}
.shop-information .row:first-child {
  margin-top:0px;
}
.shop-information .row {
  margin-top:40px;
}
.shop-information .map {
  min-height:200px;
  height:100%;
}
@media (max-width: 767.98px) {
.shop-information .details .contact {
    display: flex;
    flex-wrap: wrap;
}
.shop-information .details .contact address,
  .shop-information .details .contact > div {
    flex: 1 0 auto;
}
.shop-information .map {
    margin-top:20px;
}
}
.identifiers, .social-medias {
  margin-top:20px;
}

